<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-偏護菁英分發醫院名額
      </h5>

      <div class="dtc-search">
        <!-- <b-button variant="primary">儲存</b-button>
        <b-button variant="success">返回</b-button> -->
        <div class="mt-2" style="color:red">
          ({{ startTimeCht }} ~ {{ endTimeCht }}開放提列員額填寫)
          請於期限內完成提列員額填寫
        </div>
        <b-button variant="outline-primary" @click="getExcel"
          >匯出清單</b-button
        >
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div style="cursor: pointer;padding-top:8px;" @click="editHospital(i)">
        <b-button variant="primary" size="sm" :disabled="!isCanEdit"
          >儲存</b-button
        >
      </div>
      <div style="padding-top:3px;" :tite="item.HospitalName">
        {{ item.HospitalName }}
      </div>
      <div :tite="item.HospitalNo">
        {{ item.HospitalNo }}
      </div>
      <div :title="item.CategoryName">{{ item.CategoryName }}</div>
      <div :title="item.CountryName">{{ item.CountryName }}</div>
      <div :title="item.NeedStudent">
        <input
          type="number"
          style="width:110px;text-align: center;"
          v-model="item.NeedStudent"
          :disabled="!isCanEdit"
          min="0"
        />
      </div>
      <div>
        <b-select
          :options="['是', '否']"
          v-model="item.Kind"
          style="width: 30%;"
          :disabled="!isCanEdit"
        ></b-select>
      </div>
      <div>
        <b-input
          v-model="item.Remark"
          style="width:450px;text-align:left;margin:0 auto"
          placeholder="請輸入備註"
          :disabled="!isCanEdit"
        ></b-input>
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";

import { countryObject, majorObject, domainObject } from "@/constant.js";

const headers = [
  "操作",
  "醫院名稱",
  "代碼",
  "醫院層級",
  "縣市別",
  "提列員額",
  "三年內未考取護理師資格,醫院將以實習護士或照服員聘用意願",
  "備註",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });
// schoolObject.unshift({ value: null, text: "全部" });
majorObject.unshift({ value: null, text: "全部" });
countryObject.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      subject: "",
      selectedYear: new Date().getFullYear() - 1911,
      startTimeCht: "",
      endTimeCht: "",
      isActive: true,
      isCanEdit: true,
      domainObject,
    };
  },
  components: {},
  computed: {
    isChangeData() {
      return JSON.stringify(store.editItem);
    },
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const hospitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      return hospitalArr.includes(loginHealthCode);
    },
  },

  methods: {
    async getExcel() {
      const year = new Date().getFullYear() - 1911;
      let url = `Recruit/GetExportResult1?sYearTerm=${year}`;
      if (this.healthCodeCheck) {
        const codeHospital = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
        url = `${url}&HospitalCode=${codeHospital}`;
      }
      try {
        const res = await window.axios.get(url);
        const excelUrl = `${this.domainObject.platformOriginal}${res}`;
        window.open(excelUrl);
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getTime() {
      const year = new Date().getFullYear() - 1911;
      const url = `Recruit/GetHead?sYearTerm=${year}`;
      const { isActiver, StartDate, EndDate } = await window.axios.get(url);
      this.startTimeCht = Boolean(StartDate) ? this.$twDate(StartDate) : "";
      this.endTimeCht = Boolean(EndDate) ? this.$twDate(EndDate) : "";
      this.startTime = Boolean(StartDate) ? StartDate : null;
      this.endTime = Boolean(EndDate)
        ? `${EndDate.split("T")[0]}T23:59:59`
        : null;
      this.isActive = isActiver;
      this.isCanEdit =
        Boolean(Date.now() > Date.parse(`${this.startTime}`)) &&
        Boolean(Date.now() < Date.parse(`${this.endTime}`)) &&
        this.isActive;
    },
    async editHospital(key) {
      const year = new Date().getFullYear() - 1911;
      // const obj = { ...this.items[key] };

      const obj = {};
      obj.YearTerm = year;
      obj.HospitalCode = this.items[key].HospitalCode;
      obj.Kind = this.items[key].Kind;
      obj.NeedStudent = this.items[key].NeedStudent;
      obj.Remark = this.items[key].Remark;

      const check = Boolean(obj.Kind) && Boolean(obj.NeedStudent);
      if (!check) {
        this.$bvToast.toast(
          `請確認${this.items[key].HospitalName}之提列員額與聘用意願已進行填寫`,
          {
            title: `儲存失敗`,
            autoHideDelay: 5000,
            variant: "danger",
          }
        );
        return;
      }

      const url = `Recruit/SetNeedStudent`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    hospitalEvent() {
      // console.log("hospitalEvent");
    },
    openProgressRecord(item) {
      store.studentProgressInfo = { ...item };
      this.$bvModal.show("studentProgress");
    },
    deleteHospital(item) {
      // store.editItem = { ...item };
      alert("缺API");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.selectedYear = "";
      this.searchId = "";
      this.searchGender = null;
      this.searchBirthday = {
        year: "",
        month: "",
        day: "",
      };
      this.searchSchool = null;
      this.searchEntryYear = null;
      this.searchEndYear = null;
      this.searchMajor = null;
      this.searchCategory = null;
      this.searchHometown = null;
      this.searchStatus = null;
      this.searchServiceNow = null;
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      if (this.healthCodeCheck) {
        const codeHospital = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
        qs = this.$equalFilter("HospitalCode", qs, codeHospital);
      }

      // if (this.searchYear) {
      //   qs = `${qs}&sYearTerm=${this.searchYear}`;
      // }
      const year = new Date().getFullYear() - 1911;
      // qs = `${qs}&sYearTerm=${year}&$filter=HospitalName eq '${hospitalName}'`;
      qs = `${qs}&sYearTerm=${year}`;
      const url = `Recruit/GetYearHospitals${qs}`;

      try {
        const { Items, Count } = await window.axios.get(url);
        this.items = Items.length ? Items : [];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getHospitals() {
      try {
        const url = `Recruit/GetHospitals`;
        const res = await window.axios.get(url);
        this.hospitalOptions = res.map((s) => ({
          value: s.MId,
          text: s.MedicalName,
          sid: s.Sid,
        }));
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.getHospitals();
    this.getData();
    this.getTime();
    this.$root.$on("get-Newest-Hospitals", () => this.getData());
    this.$root.$on("get-Newest-HospitalsTime", () => this.getTime());
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 120px 320px 200px 250px 250px 180px 420px 500px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 50px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: max-content max-content max-content 500px repeat(
      30,
      max-content
    );
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}
div.hospital > div > div.input-group-text {
  width: 240px;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  height: 92px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>

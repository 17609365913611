<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-新增偏護菁英分發醫院清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="年度">
          <b-input
            v-model.trim="searchYear"
            placeholder="請輸入年度"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-button variant="primary" @click="searchDb">搜尋</b-button>
      </div>

      <div class="dtc-search">
        <b-button
          variant="warning"
          @click="openAddDialog"
          :disabled="!isCanEdit"
          ><i class="fas fa-plus"></i>新增</b-button
        >

        <!-- <b-button variant="primary">儲存</b-button>
        <b-button variant="success">返回</b-button> -->
        <b-button variant="light" @click="openEditTimeDialog"
          >開放分發服務醫院日期設定</b-button
        >
        <div class="mt-2" style="color:red">
          ({{ $twDate(startTime) }} ~ {{ $twDate(endTime) }}，
          {{ isActive ? "啟用" : "停用" }})
        </div>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div style="cursor: pointer;padding-top:3px;">
        <b-button
          variant="danger"
          size="sm"
          @click="deleteHospital(item)"
          :disabled="!isCanEdit"
          >刪除</b-button
        >
      </div>
      <div :title="item.HospitalName">
        <!-- <b-select
          :options="hospitalOptions"
          v-model="item.HospitalCode"
          @change="hospitalEvent"
        ></b-select> -->
        {{ item.HospitalName }}
      </div>
      <div :title="item.HospitalNo">{{ item.HospitalNo || "暫無資料" }}</div>
      <div :title="item.CategoryName">
        {{ item.CategoryName || "暫無資料" }}
      </div>
      <div :title="item.CountryName">{{ item.CountryName || "暫無資料" }}</div>
    </main>

    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <b-modal id="modalTimeEdit" title="開放分發服務醫院日期設定" size="md">
      <b-input-group prepend="設定年度">
        <b-input
          type="number"
          min="0"
          max="999"
          v-model="yearEdit"
          @change="setEditYear"
          disabled
        ></b-input>
      </b-input-group>

      <b-input-group prepend="開始日期" class="mt-2">
        <!-- <b-input type="date" v-model="startEditTime"></b-input> -->
        <div class="search-birthday">
          <b-input
            v-model.trim="startEditTime.year"
            placeholder="民國年"
            type="number"
            disabled
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="startEditTime.month"
            placeholder="月"
            type="number"
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="startEditTime.day"
            placeholder="日"
            type="number"
          ></b-input>
        </div>
      </b-input-group>
      <b-input-group prepend="結束日期" class="mt-2">
        <!-- <b-input type="date" v-model="endEditTime"></b-input> -->
        <div class="search-birthday">
          <b-input
            v-model.trim="endEditTime.year"
            placeholder="民國年"
            type="number"
            disabled
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="endEditTime.month"
            placeholder="月"
            type="number"
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="endEditTime.day"
            placeholder="日"
            type="number"
          ></b-input>
        </div>
      </b-input-group>
      <b-check size="lg" switch v-model="isEditOpen" class="mt-2 mr-1">
        啟用
      </b-check>

      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right mr-1"
            @click="editTime"
            :disabled="!isTimeEditAllData"
            >確定</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal id="modalHospitalAdd" title="新增偏護菁英分發醫院清單" size="lg">
      <b-input-group prepend="設定年度">
        <b-input
          type="number"
          min="0"
          max="999"
          v-model="yearAdd"
          disabled
        ></b-input>
      </b-input-group>
      <b-input-group prepend="代碼" class="mt-2">
        <span style="margin:8px 6px 0 10px">H</span>
        <b-input
          type="number"
          v-model="codeAdd"
          placeholder="請輸入代碼"
          disabled
        ></b-input>
      </b-input-group>
      <b-input-group prepend="醫院" class="mt-2">
        <!-- <b-select :options="hospitalOptions" v-model="hospitalAdd"></b-select> -->
        <treeselect
          style="width: 665.9px;color:#333"
          v-model="hospitalAdd"
          :options="hospitalOptions"
          placeholder="選擇醫院"
          noResultsText="查無此醫院..."
        />
      </b-input-group>

      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right mr-1"
            @click="addHospital"
            :disabled="!isAddDataAll"
            >確定</b-button
          >
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import { countryObject, majorObject } from "@/constant.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const headers = ["操作", "醫院名稱", "代碼", "醫院層級", "縣市別"];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });
// schoolObject.unshift({ value: null, text: "全部" });
majorObject.unshift({ value: null, text: "全部" });
countryObject.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      searchYear: new Date().getFullYear() - 1911,
      yearEdit: "",
      startEditTime: {
        year: "",
        month: "",
        day: "",
      },
      endEditTime: {
        year: "",
        month: "",
        day: "",
      },
      isEditOpen: false,
      hospitalOptions: [],
      yearAdd: "",
      codeAdd: "",
      hospitalAdd: null,
      startTime: null,
      endTime: null,
      isActive: true,
      originOstartDate: null,
      originOendDate: null,
    };
  },
  components: {
    Treeselect,
  },
  computed: {
    isChangeData() {
      return JSON.stringify(store.editItem);
    },
    isTimeEditAllData() {
      return (
        Boolean(this.yearEdit) &&
        Boolean(this.startEditTime.month) &&
        Boolean(this.startEditTime.day) &&
        Boolean(this.endEditTime.month) &&
        Boolean(this.endEditTime.day)
      );
    },
    isAddDataAll() {
      return (
        Boolean(this.yearAdd) &&
        Boolean(this.codeAdd) &&
        Boolean(this.hospitalAdd)
      );
    },
    isCanEdit() {
      return (
        Boolean(Date.now() > Date.parse(`${this.startTime}`)) &&
        Boolean(Date.now() < Date.parse(`${this.endTime}`)) &&
        this.isActive
      );
    },
    isOldYear() {
      const now = new Date().getFullYear() - 1911;
      return Boolean(+now > +this.searchYear);
    },
  },

  methods: {
    setEditYear() {
      this.startEditTime.year = this.yearEdit;
      this.endEditTime.year = this.yearEdit;
    },
    async getTime() {
      const url = `Recruit/GetHead?sYearTerm=${this.searchYear}`;
      const {
        isActiver,
        StartDate,
        EndDate,
        OstartDate,
        OendDate,
      } = await window.axios.get(url);
      this.startTime = Boolean(StartDate) ? StartDate : null;
      this.endTime = Boolean(EndDate)
        ? `${EndDate.split("T")[0]}T23:59:59`
        : null;
      this.isActive = isActiver;
      this.originOstartDate = OstartDate;
      this.originOendDate = OendDate;
    },
    openEditTimeDialog() {
      const startEditTime = this.$twDate(this.startTime);
      const endEditTime = this.$twDate(this.endTime);

      this.yearEdit = new Date().getFullYear() - 1911;
      this.startEditTime = {
        year: startEditTime.split("-")[0],
        month: startEditTime.split("-")[1],
        day: startEditTime.split("-")[2],
      };
      this.endEditTime = {
        year: endEditTime.split("-")[0],
        month: endEditTime.split("-")[1],
        day: endEditTime.split("-")[2],
      };
      this.isEditOpen = this.isActive;
      this.setEditYear();
      this.$bvModal.show("modalTimeEdit");
    },
    async editTime() {
      const startdate = this.$usDate(
        `${this.startEditTime.year}-${this.startEditTime.month}-${this.startEditTime.day}`
      );
      const enddate = this.$usDate(
        `${this.endEditTime.year}-${this.endEditTime.month}-${this.endEditTime.day}`
      );
      const obj = {
        isActiver: this.isEditOpen,
        StartDate: startdate,
        EndDate: enddate,
        OstartDate: this.originOstartDate,
        OendDate: this.originOendDate,
      };
      try {
        await window.axios.post(
          `Recruit/SetHead?sYearTerm=${this.yearEdit}`,
          obj
        );
        this.$bvToast.toast(`日期設定成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getTime();
        this.$bvModal.hide("modalTimeEdit");
      } catch (e) {
        this.$bvToast.toast(`日期設定失敗${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openAddDialog() {
      // const length = this.items.length;

      // let num = Boolean(length)
      //   ? +this.items[length - 1]["HospitalNo"].split("H")[1] + 1
      //   : 1;
      let num = this.rowCount + 1;
      if (+num < 10) {
        num = `00${num}`;
      } else if (+num >= 10 && +num < 100) {
        num = `0${num}`;
      }

      this.hospitalAdd = null;
      this.yearAdd = new Date().getFullYear() - 1911;
      this.codeAdd = num;
      this.$bvModal.show("modalHospitalAdd");
    },
    async addHospital() {
      const obj = {
        YearTerm: this.yearAdd,
        HospitalNo: `H${this.codeAdd}`,
        HospitalCode: this.hospitalAdd,
      };
      try {
        await window.axios.post(`Recruit/AddHospital`, obj);
        this.$bvToast.toast(`醫院新增成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
        this.$bvModal.hide("modalHospitalAdd");
      } catch (e) {
        this.$bvToast.toast(`醫院新增失敗，${e.response.data.Message}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteHospital(item) {
      const url = `Recruit/DeleteHospital?SeqNo=${item.SeqNo}`;
      try {
        await window.axios.delete(url);
        this.getData();
        this.$bvToast.toast(`醫院刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`醫院刪除失敗${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      if (this.searchYear) {
        qs = `${qs}&sYearTerm=${this.searchYear}`;
        this.getTime();
      }
      const url = `Recruit/GetYearHospitals${qs}`;

      try {
        const { Items, Count } = await window.axios.get(url);
        this.items = Items.length ? Items : [];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getHospitals() {
      try {
        const url = `Recruit/GetHospitals`;
        const res = await window.axios.get(url);
        // this.hospitalOptions = res.map((s) => ({
        //   value: s.MId,
        //   text: s.MedicalName,
        //   sid: s.Sid,
        // }));
        this.hospitalOptions = res.map((s) => ({
          id: s.MId,
          label: s.MedicalName,
        }));
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.getHospitals();
    this.getData();
    this.$root.$on("get-Newest-Tab1Hospitals", () => this.getData());
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    "startEditTime.month"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.startEditTime.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.startEditTime.month = result));
    },
    "startEditTime.day"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.startEditTime.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.startEditTime.day = result));
    },
    "endEditTime.month"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.endEditTime.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.endEditTime.month = result));
    },
    "endEditTime.day"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.endEditTime.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.endEditTime.day = result));
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 140px 500px 400px 400px 500px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 46px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: max-content max-content max-content 280px repeat(
      30,
      max-content
    );
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}
div.hospital > div > div.input-group-text {
  width: 240px;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .btn-light {
  background: #165a99;
  border-color: #165a99;
  color: white;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep main .custom-select {
  font-size: 1.05rem !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 160px 12.5px 91px 12.5px 91px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
</style>

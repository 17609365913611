<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-偏護菁英分發醫院核定員額清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="年度">
          <b-input
            v-model.trim="searchYear"
            placeholder="請輸入年度"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-button variant="primary" size="sm" @click="searchDb">搜尋</b-button>
        <b-button variant="outline-primary" @click="getExcel"
          >匯出清單</b-button
        >
        <b-button variant="light" @click="openEditTimeDialog"
          >偏護菁英志願選填日期設定</b-button
        >
        <div class="mt-2" style="color:red">
          ({{ $twDate(startTime) }} ~
          {{ $twDate(endTime) }})請於期限內完成核定員額填寫
        </div>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div style="cursor: pointer;padding-top:3px;" :title="item.HospitalName">
        {{ item.HospitalName }}
      </div>
      <div :title="item.HospitalNo">
        {{ item.HospitalNo }}
      </div>
      <div :title="item.CategoryName">{{ item.CategoryName }}</div>
      <div :title="item.CountryName">{{ item.CountryName }}</div>
      <div :title="item.NeedStudent">{{ item.NeedStudent }}</div>
      <div :title="item.Approved">
        <b-input
          type="number"
          max="999"
          min="0"
          v-model="item.Approved"
          placeholder="員額"
          style="width:120px;margin:0 auto;text-align:center"
          @change="editApprove(i)"
          :disabled="!isCanEdit"
        ></b-input>
      </div>
      <div :title="item.Kind" :style="item.Kind == '否' ? 'color:red' : ''">
        {{ item.Kind }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <b-modal id="modalTab3TimeEdit" title="偏護菁英志願選填日期設定" size="md">
      <b-input-group prepend="設定年度">
        <b-input
          type="number"
          min="0"
          max="999"
          v-model="yearEdit"
          @change="setEditYear"
          disabled
        ></b-input>
      </b-input-group>
      <b-input-group prepend="開始日期" class="mt-2">
        <!-- <b-input type="date" v-model="startEditTime"></b-input> -->
        <div class="search-birthday">
          <b-input
            v-model.trim="startEditTime.year"
            placeholder="民國年"
            type="number"
            disabled
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="startEditTime.month"
            placeholder="月"
            type="number"
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="startEditTime.day"
            placeholder="日"
            type="number"
          ></b-input>
        </div>
      </b-input-group>
      <b-input-group prepend="結束日期" class="mt-2">
        <!-- <b-input type="date" v-model="endEditTime"></b-input> -->
        <div class="search-birthday">
          <b-input
            v-model.trim="endEditTime.year"
            placeholder="民國年"
            type="number"
            disabled
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="endEditTime.month"
            placeholder="月"
            type="number"
          ></b-input>
          <span class="dash">-</span>
          <b-input
            v-model.trim="endEditTime.day"
            placeholder="日"
            type="number"
          ></b-input>
        </div>
      </b-input-group>
      <!-- <b-check size="lg" switch v-model="isEditOpen" class="mt-2 mr-1">
        啟用
      </b-check> -->

      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right mr-1"
            @click="editTime"
            :disabled="!isTimeEditAllData"
            >確定</b-button
          >
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";

import { countryObject, majorObject, domainObject } from "@/constant.js";

const headers = [
  "醫院名稱",
  "代碼",
  "醫院層級",
  "縣市別",
  "提列員額",
  "核定員額",
  "三年內未考取護理師資格,醫院將以實習護士或照服員聘用意願",
  "備註",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });
// schoolObject.unshift({ value: null, text: "全部" });
majorObject.unshift({ value: null, text: "全部" });
countryObject.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      settlementYear: "",
      searchYear: new Date().getFullYear() - 1911,
      yearEdit: "",
      startEditTime: {
        year: "",
        month: "",
        day: "",
      },
      endEditTime: {
        year: "",
        month: "",
        day: "",
      },
      isEditOpen: false,
      startTime: null,
      endTime: null,
      isActive: true,
      originStartDate: null,
      originEndDate: null,
      reswllInfo: `${window.sessionStorage.fullname}`.replace(/\"/g, ""),
      domainObject,
    };
  },
  components: {},
  computed: {
    isChangeData() {
      return JSON.stringify(store.editItem);
    },
    isTimeEditAllData() {
      return (
        Boolean(this.yearEdit) &&
        Boolean(this.startEditTime.month) &&
        Boolean(this.startEditTime.day) &&
        Boolean(this.endEditTime.month) &&
        Boolean(this.endEditTime.day)
      );
    },
    isCanEdit() {
      return (
        Boolean(Date.now() > Date.parse(`${this.startTime}`)) &&
        Boolean(Date.now() < Date.parse(`${this.endTime}`))
      );
    },
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const hospitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      return hospitalArr.includes(loginHealthCode);
    },
  },

  methods: {
    async getExcel() {
      // const year = new Date().getFullYear() - 1911;
      const year = this.searchYear;
      let url = `Recruit/GetExportResult2?sYearTerm=${year}`;
      if (this.healthCodeCheck) {
        const codeHospital = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
        url = `${url}&HospitalCode=${codeHospital}`;
      }
      try {
        const res = await window.axios.get(url);
        const excelUrl = `${this.domainObject.platformOriginal}${res}`;
        window.open(excelUrl);
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    setEditYear() {
      this.startEditTime.year = this.yearEdit;
      this.endEditTime.year = this.yearEdit;
    },
    async getTime() {
      const url = `Recruit/GetHead?sYearTerm=${this.searchYear}`;
      const {
        StartDate,
        EndDate,
        isActiver,
        OstartDate,
        OendDate,
      } = await window.axios.get(url);
      this.startTime = Boolean(OstartDate) ? OstartDate : null;
      this.endTime = Boolean(OendDate)
        ? `${OendDate.split("T")[0]}T23:59:59`
        : null;
      this.isActive = isActiver;
      this.originStartDate = StartDate;
      this.originEndDate = EndDate;
    },
    openEditTimeDialog() {
      const startEditTime = this.$twDate(this.startTime);
      const endEditTime = this.$twDate(this.endTime);
      this.yearEdit = new Date().getFullYear() - 1911;
      this.startEditTime = {
        year: startEditTime.split("-")[0],
        month: startEditTime.split("-")[1],
        day: startEditTime.split("-")[2],
      };
      this.endEditTime = {
        year: endEditTime.split("-")[0],
        month: endEditTime.split("-")[1],
        day: endEditTime.split("-")[2],
      };
      // this.isEditOpen = false;
      this.setEditYear();
      this.$bvModal.show("modalTab3TimeEdit");
    },
    async editTime() {
      const startdate = this.$usDate(
        `${this.startEditTime.year}-${this.startEditTime.month}-${this.startEditTime.day}`
      );
      const enddate = this.$usDate(
        `${this.endEditTime.year}-${this.endEditTime.month}-${this.endEditTime.day}`
      );
      const obj = {
        OstartDate: startdate,
        OendDate: enddate,
        isActiver: this.isActive,
        StartDate: this.originStartDate,
        EndDate: this.originEndDate,
      };
      try {
        await window.axios.post(
          `Recruit/SetHead?sYearTerm=${this.yearEdit}`,
          obj
        );
        this.$bvToast.toast(`日期設定成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getTime();
        this.$bvModal.hide("modalTab3TimeEdit");
      } catch (e) {
        this.$bvToast.toast(`日期設定失敗${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async editApprove(key) {
      if (!Boolean(this.searchYear)) {
        this.$bvToast.toast(`儲存失敗，請在上方搜尋框中輸入核定員額年度`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      const obj = {};
      obj.YearTerm = this.searchYear;
      obj.HospitalCode = this.items[key].HospitalCode;
      obj.Approved = this.items[key].Approved;

      const url = `Recruit/SetApproved`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openProgressRecord(item) {
      store.studentProgressInfo = { ...item };
      this.$bvModal.show("studentProgress");
    },
    deleteHospital(item) {
      // store.editItem = { ...item };
      alert("缺API");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      if (this.searchYear) {
        qs = `${qs}&sYearTerm=${this.searchYear}`;
        this.getTime();
      }
      if (this.healthCodeCheck) {
        const codeHospital = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
        qs = this.$equalFilter("HospitalCode", qs, codeHospital);
      }
      const url = `Recruit/GetYearHospitals${qs}`;

      try {
        const { Items, Count } = await window.axios.get(url);
        this.items = Items.length ? Items : [];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
        const isInList = this.items.find((s) =>
          s.HospitalName.includes(this.reswllInfo)
        )
          ? true
          : false;
        if (isInList) {
          this.items = this.items.filter((s) =>
            s.HospitalName.includes(this.reswllInfo)
          );
          this.rowCount = this.items.length;
          this.totalCountStr = `共${this.items.length} 筆`;
        }
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getHospitals() {
      try {
        const url = `Recruit/GetHospitals`;
        const res = await window.axios.get(url);
        this.hospitalOptions = res.map((s) => ({
          value: s.MId,
          text: s.MedicalName,
          sid: s.Sid,
        }));
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.getHospitals();
    this.getData();
    this.$root.$on("get-Newest-Tab3Hospitals", () => this.getData());
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    "startEditTime.month"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.startEditTime.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.startEditTime.month = result));
    },
    "startEditTime.day"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.startEditTime.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.startEditTime.day = result));
    },
    "endEditTime.month"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.endEditTime.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.endEditTime.month = result));
    },
    "endEditTime.day"(v) {
      let value = `${v}`;
      if (+v < 0) {
        this.endEditTime.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.endEditTime.day = result));
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 350px 120px 260px 160px 180px 180px 400px 420px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 46px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: max-content repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}
div.hospital > div > div.input-group-text {
  width: 240px;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  // height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .btn-light {
  background: #165a99;
  border-color: #165a99;
  color: white;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep main .custom-select {
  font-size: 1.05rem !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 160px 12.5px 91px 12.5px 91px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
</style>
